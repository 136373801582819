<template>
	<div class="perfectInformation">
		<div class="perfectInformation_pop">
			<div class="perfectInformation_from">
				<el-form class="perfectInformation_ul perfectInformation-ruleForm" :model="ruleForm" ref="ruleForm" label-width="50px" :label-position="labelPosition">
					<el-form-item class="perfectInformation_li" label="姓名"><el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input></el-form-item>
					<el-form-item class="perfectInformation_li" label="医院">
						<el-autocomplete
							class="inline-input"
							v-model="ruleForm.hospital"
							:fetch-suggestions="querySearch"
							placeholder="请输入医院"
							:trigger-on-focus="false"
							@select="handleSelect"
						></el-autocomplete>
					</el-form-item>
					<el-form-item class="perfectInformation_li" label="科室">
						<el-cascader
							style="width:100%"
							v-model="ruleForm.department"
							:options="perfectData.departmentArr"
							:props="department_props"
							:separator="separator"
							placeholder="请选择科室"
							:value="ruleForm.department"
							@change="departmentChange"
						>
							<template slot-scope="{ node, data }">
								<span>{{ data.label }}</span>
							</template>
						</el-cascader>
					</el-form-item>
					<el-form-item class="perfectInformation_li" label="城市">
						<el-cascader style="width:100%"
							v-model="ruleForm.city"
							:options="perfectData.cityArr"
							placeholder="请选择城市"
							:props="city_props"
							:value="ruleForm.city"
							@change="cityChange">
							<template slot-scope="{ node, data }">
								<span>{{ data.name }}</span>
							</template>
						</el-cascader>
					</el-form-item>
					<div class="perfectInformation_li perfectInformation_upload">
						<div class="perfectInformation_uploadTitle">
							<p>请上传您的工牌</p>
							<p>或排班表</p>
						</div>
						<div class="perfectInformation_uploadBox">
							<el-upload
								class="upload-demo"
								ref="upload"
								list-type="picture-card"
								:action="urlConfig.baseUrl"
								:on-preview="handlePreview"
								:on-remove="handleRemove"
								:on-change="handChange"
								:file-list="ruleForm.fileList"
								:limit="limit"
								:on-exceed="handMaxLength"
								:auto-upload="false"
							></el-upload>
						</div>
					</div>
					<div class="perfectInformation_letter clearfix">
						<div @click.stop="agreeStatus('')">
							<div class="perfectInformation_agreeStatus"><div class="perfectInformation_agreeStatus_cont" v-if="ruleForm.agreeStatus"></div></div>
							<span class="notes">我已阅读</span>
						</div>
						<span class="letterBox" @click.stop="lookLetter()">《个人信息收集同意书》</span>
						<span class="notes_sub">以及其中所述对</span>
						<span class="notes padL20">我个人信息的跨境转移</span>
					</div>
					<div v-if="perfectData.isShowSubmitBtn" class="perfectInformation_li perfectInformation_btn"><el-button round @click.stop="perfectInfo(ruleForm)">提交</el-button></div>
					<div v-if="perfectData.loginEdNeedApple" class="popLetter_sureBtn popLetter_cancelBtn" @click.stop="closePerfect()">取消</div>
				</el-form>
			</div>
			<div class="perfectInformation_infoNotes">{{ popNotes }}</div>
		</div>
		<el-dialog :visible.sync="lookBigWork" :close-on-click-modal="false" :show-close="false" :modal-append-to-body="false" center>
			<div style="width: 100%;text-align: center"><img :src="showWorkImg" alt="上传的工牌、排班表" /></div>
			<span slot="footer" class="dialog-footer"><el-button type="primary" @click.stop="lookBigWork = false">确 定</el-button></span>
		</el-dialog>
		<div class="popLetter_black" v-if="popLetter">
			<div class="popLetter">
				<div class="popLetter_box"><div class="popLetter_content" v-html="perfectData.agreeMentObj.content"></div></div>
				<div class="popLetter_sureBtn" @click.stop="agreeStatus('sure')">确认</div>
				<div class="popLetter_notes">
					<img alt="" class="icon" src="../icon/icon1.png" />
					<div class="text">个人信息收集同意书</div>
				</div>
			</div>
		</div>
		<div v-if="fullscreenLoading" class="popLetter_fullscreenLoadingBox">
			<div class="popLetter_fullscreenLoading" element-loading-text="信息提交中" element-loading-background="rgba(0, 0, 0, 0.8)" element-loading-spinner="el-icon-loading" v-loading="fullscreenLoading"></div>
		</div>
	</div>
</template>

<script>
import urlConfig from '@/config/urlConfig';
import indexApi from '@/api';
let callBackUrl;
export default {
	props: {
		perfectData: {
			type: Object,
			default: function() {
				return {
					agreeMentObj: { content: `<div></div>` }
				};
			}
		},
		userNewObj: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			fullscreenLoading: false, //是否显示提交状态
			separator: '/', //科室选择分隔符
			popLetter: false, //是否显示知情同意书
			popNotes: '您的临时身份验证失败，无法进行登录，请完善信息验证您的身份',
			urlConfig,
			limit: 3, //上传工牌最大数量
			labelPosition: 'left', //form表单左对齐
			ruleForm: { //前端展示补充的完善信息数据
				name: "", //姓名
				hospital: "", //医院
				department: [], //科室
				city: [], //城市
				agreeStatus: false,
				fileList: [],
			},
			restaurants: [], //医院模糊搜索数据
			department_props: {
				//科室选择的配置选项
				value: 'value',
				label: 'label',
				children: 'children'
			},
			city_props: {
				//城市选择的配置选项
				value: 'name',
				label: 'name',
				children: 'city'
			},
			showWorkImg: '', //预览工牌、排班表图片地址
			lookBigWork: false, //预览工牌、排班表图片
			timeout: null //医院模糊查询定时器
		};
	},
	created() {
		let that = this;
    callBackUrl = that.$route.query.callBackUrl;
		console.log('完善信息组件开始创建', that.perfectData,that.userNewObj);
		if (that.userNewObj && that.userNewObj.appealUser){
			that.ruleForm["name"] = that.userNewObj.appealUser.name;
			that.ruleForm["hospital"] = that.userNewObj.appealUser.hcoName;
			that.ruleForm["department"] = [that.userNewObj.appealUser.firstDeparment,that.userNewObj.appealUser.secondDeparment];
			that.ruleForm["city"] = [that.userNewObj.appealUser.province,that.userNewObj.appealUser.city];
			that.userNewObj.appealUser.picUrlPath.forEach((item,index)=>{
				that.ruleForm["fileList"].push({url:item,file:item})
			})
			that.popNotes = that.userNewObj.appealUser.message;
      // if (that.userNewObj.isAppeal === 1 && that.userNewObj.state === "invalid") {
        let token = that.userNewObj && that.userNewObj.token;
        that.$stroage.setLocalItem('token', token);
      // }
			console.log(that.ruleForm);
		}
	},
	mounted() {},
	methods: {
		//关闭登录用户一直显示申诉信息
		closePerfect(){
			let that = this;
			that.$parent.loginSuccess_perfectInfoStatus = false;
			that.$parent.isShowSubmitBtn = false;
			that.$stroage.setLocalItem('loginEdNeedApple', false);
			that.$stroage.setLocalItem('userNewObj', {});
		},
		//是否同意知情同意书
		agreeStatus(type, flag) {
			let that = this;
			if (type === 'sure' || type === 'cancel') {
				that.popLetter = false;
			} else {
				that.ruleForm.agreeStatus = !that.ruleForm.agreeStatus;
			}
		},
		//知情同意书
		lookLetter() {
			let that = this;
			console.log('子组件点击了确认按钮');
			that.popLetter = !that.popLetter;
		},
		//提交用户补充的完善信息
		perfectInfo(infoData) {
			let that = this;
			console.log('提交的完善信息', infoData);
			console.log('这是提交完善数据之后需要进行判断的数据对象:', that.userNewObj,that.ruleForm);
			if (!infoData.name) {
				that.$message.error('请输入您的姓名');
			} else if (!infoData.hospital) {
				that.$message.error('请选择您的医院');
			} else if (!infoData.department) {
				that.$message.error('请选择您的科室');
			} else if (!infoData.city) {
				that.$message.error('请选择您所在城市');
			} else if (infoData.fileList.length == 0) {
				that.$message.error('请上传您的工牌、执业证照');
			} else if (!infoData.agreeStatus) {
				that.$message.error('请阅读并勾选知情同意书');
			} else {
				let formData = new FormData();
				let picList = [];
				infoData.fileList.forEach((item,index)=>{
					formData.append("picList",item.file);
				});
				let params = {
					fullname: infoData.name,
					hcoName: infoData.hospital,
					firstDeparment: infoData.department[0],
					secondDeparment: infoData.department[1],
					province: infoData.city[0],
					city: infoData.city[1],
					platform: 'pc',
					isPrivacy: infoData.agreeStatus?1:0,
				}
				for(let parItem in params){
					formData.append(parItem, params[parItem]);
				}
				console.log("提交的用户信息:",formData);

				that.fullscreenLoading = true;
				indexApi.doctorAppeal(formData).then((res)=>{
					console.log(res.code,res.success,res)
					that.fullscreenLoading = false;
					if (res.code === 1000 && res.success){
            if (callBackUrl) {
              let token = res.data && res.data.token;
              that.$stroage.setLocalItem('token', token);
              that.$store.commit('setUserName', res.data.userInfo.name);
              that.$store.commit('setUserAvatar', res.data.userInfo.avatar);
              that.$message.success(res.data.message);
              const {href} = this.$router.resolve({name: "Quanshi"});
              let loginPath = `${href}?callbackUrl=${callBackUrl}`;
              console.log("loginPath", loginPath)
              localStorage.setItem('fakeLogin', true);
              location.replace(loginPath);
            } else {
              if (that.userNewObj && that.userNewObj.isViewCme === 0) { //0不可以查看系统
                that.$message.error(res.message);
                that.$parent.loginSuccess_perfectInfoStatus = false;
                that.$parent.isShowSubmitBtn = false;
                that.$stroage.setLocalItem('token', "");
                return;
              } else {
                let token = res.data && res.data.token;
                that.$stroage.setLocalItem('token', token);
                that.$store.commit('setUserName', res.data.userInfo.name);
                that.$store.commit('setUserAvatar', res.data.userInfo.avatar);
                that.$message.success(res.data.message);
                if (that.userNewObj && that.userNewObj.tagStatus === 1) { //选择标签
                  that.$router.push({ name: 'registerNext'});
                } else if (that.userNewObj && that.userNewObj.tagStatus === 0) { //进入首页
                  that.$router.push({ path: '/home' });
                }
                localStorage.setItem('fakeLogin',false);
              }
            }
					} else if (res.code === 0 && !res.success) {
						console.log("申诉失败:",res.message);
						that.$message.error(res.message);
					}
				})
			}
		},
		handleChange(data) {
			this.cityId = data && data.length > 1 && data[1];
		},
		//科室选择
		departmentChange(value) {
			let that = this;
			console.log(value, '选择的科室');
			if (value[0] === '') {
				that.separator = '';
			} else {
				that.separator = '/';
			}
		},
		//城市选择
		cityChange(value) {
			let that = this;
			console.log('选择的城市', value);
		},
		//删除已上传工牌、排班表
		handleRemove(file, fileList) {
			let that = this;
			console.log("删除工牌之后剩余的工牌列表数据",file, fileList);
			that.ruleForm.fileList = fileList;
		},
		//上传工牌、排班表
		handChange(file, fileList) {
			let that = this;
			console.log('选择的工牌', file, fileList);
			let imgArr = {
				name: file.name,
				url: file.url,
				file: file.raw
			};
			that.ruleForm.fileList.push(imgArr);
		},
		//预览上传的图片
		handlePreview(file) {
			let that = this;
			that.showWorkImg = file.url;
			that.lookBigWork = true;
		},
		//设置工牌、排班表上传最大值-3张
		handMaxLength(files, fileList) {
			let that = this;
			if (fileList.length >= 3) {
				that.$message.warning('最多上传三张工牌、执业证照');
			}
			console.log('上传超过最大值', files, fileList);
		},
		//医院模糊搜索
		querySearch(queryString, cb) {
			let that = this;
			console.log(queryString, '输入的内容');
			let params = { name: queryString };
			indexApi.getHospital(params).then(
				res => {
					let restaurants = [];
					if (res.code === 1000) {
						let itemObj = {};
						res.data.forEach((item, index) => {
							itemObj = {
								value: item.corporate_name_v,
								ms_hco_code_c: item.ms_hco_code_c
							};
							restaurants.push(itemObj);
						});
						cb(restaurants);
					} else {
						cb([]);
					}
				},
				err => {
					console.log(err);
				}
			);
		},
		//点击选中模糊医院建议项时触发
		handleSelect(item) {
			console.log('选择了医院模糊搜索中的这个医院:', item);
			let that = this;
			that.ruleForm.hospital = item.value;
		}
	}
};
</script>

<style lang="scss">
// 完善信息
.perfectInformation {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 100;
	.perfectInformation_pop {
		width: 520px;
		min-height: 750px;
		margin-top: 20px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		background: url('../icon/bg-p.png') no-repeat left top;
		background-size: 100% 750px;
		padding: 170px 60px 0 40px;
		.perfectInformation_infoNotes {
			    width: 240px;
			    height: 90px;
			    display: flex;
			    flex-direction: column;
			    justify-content: center;
			    align-items: center;
			    position: absolute;
			    top: 20px;
			    right: 40px;
			    font-size: 18px;
			    color: #ffffff;
			    line-height: 23px;
		}
		.perfectInformation_from {
			width: 100%;
			height: 500px;
			overflow-y: auto;
			overflow-x: hidden;
			margin: 0 auto;
			border-top-right-radius: 60px;
			.perfectInformation_ul {
				width: 100%;
				margin: 0 auto;
				.perfectInformation_letter {
					width: 100%;
					margin-bottom: 20px;
					.perfectInformation_agreeStatus,
					.notes,
					.letterBox {
						float: left;
					}
					.perfectInformation_agreeStatus {
						width: 20px;
						height: 20px;
						border: 1px solid #503291;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						.perfectInformation_agreeStatus_cont {
							width: 10px;
							height: 10px;
							border: 1px solid #503291;
							border-radius: 50%;
							background: #eb3c96;
						}
						.el-checkbox__input.is-checked .el-checkbox__inner {
							background-color: #eb3c96;
							border-color: #eb3c96;
						}
						.el-checkbox__input.is-focus .el-checkbox__inner {
							border-color: #eb3c96;
						}
					}
					.notes,
					.notes_sub,
					.letterBox {
						font-size: 17.35px;
						color: #eb3c96;
						line-height: 22px;
						cursor: pointer;
					}
					.notes {
						color: #333333;
						margin-left: 8px;
					}
					.notes_sub{
						color: #333333;
					}
					.padL20{
						padding-left: 20px
					}
				}
				.perfectInformation_li {
					width: 100%;
					margin: 0 auto;
					padding: 5px 0;
					border-bottom: 1px solid #e2e2e2;

					.el-form-item__label {
						font-size: 18px;
						line-height: 40px;
						color: #8f8f8f;
						padding: 0;
					}
					.el-form-item__content .el-select,
					.el-form-item__content .el-select .el-input,
					.el-form-item__content .el-input,
					.el-form-item__content .el-autocomplete,
					.el-form-item__content .el-autocomplete .el-input {
						width: 100%;
						.el-input__inner {
							width: 100%;
							margin: 0 auto;
							border: 0;
							font-size: 18px;
							color: #000000;
							background: transparent;
							padding-left: 0;
						}
					}
					.el-autocomplete .el-cascader .el-input--suffix .el-input__suffix,
					.el-input__suffix-inner .el-input__icon:after,
					.el-autocomplete .el-cascader .el-input--suffix .el-input__suffix,
					.el-input__suffix-inner .el-input__icon:before {
						content: '';
					}
					.el-autocomplete .el-cascader .el-input--suffix .el-input__suffix,
					.el-input__suffix-inner .el-input__icon {
						background: url('../icon/select.png') no-repeat center;
						background-size: 13px 7px;
					}
					//工牌、排班表上传
					&.perfectInformation_upload {
						width: 100%;
						margin: 20px auto;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						border-bottom: 0;
						.perfectInformation_uploadTitle p {
							font-size: 18px;
							color: #8f8f8f;
							line-height: 23px;
							margin-bottom: 5px;

							&:last-child {
								margin-bottom: 0;
							}
						}
						.el-upload--picture-card,
						.perfectInformation_uploadBox .el-upload-list--picture-card .el-upload-list__item {
							width: 63px;
							height: 63px;
						}
						.perfectInformation_uploadBox {
							margin-left: 8px;
						}
						.el-upload--picture-card {
							background: url('../icon/work.png') no-repeat center;
							background-size: 63px 63px;
							border: 0;
							.avatar-uploader,
							.el-upload--text,
							.el-icon-plus {
								width: 100%;
								height: 100%;
							}
							.avatar {
								width: 178px;
								height: 178px;
								display: block;
							}
							.el-icon-plus:before {
								content: '';
							}
						}
					}
					//提交按钮
					&.perfectInformation_btn {
						width: 100%;
						margin: 0 auto;
						text-align: center;
						border-bottom: 0;

						.el-form-item__content {
							margin-left: 0 !important;
						}

						button.el-button {
							margin: 0;
							padding: 0;
							width: 334px;
							height: 47px;
							line-height: 47px;
							border-radius: 24px;
							background: #503291;
							font-size: 20.24px;
							text-align: center;
							color: #ffffff;
						}
					}
				}
			}
		}
	}
	.popLetter_black {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 100;
		.popLetter {
			width: 520px;
			min-height: 750px;
			margin-top: 20px;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);
			background: url('../icon/bg-letter.png') no-repeat left top;
			background-size: 100% 750px;
			padding: 170px 60px 0 40px;
			.popLetter_box {
				width: 100%;
				margin: 0 auto;
				height: 390px;
				overflow-x: hidden;
				overflow-y: auto;
				&.fiveHeight {
					height: 480px;
				}
				&::-webkit-scrollbar {
					width: 15px;
				}
				&::-webkit-scrollbar-track {
					background-color: #d8d8d8;
					-webkit-border-radius: 2em;
					-moz-border-radius: 2em;
					border-radius: 2em;
				}
				&::-webkit-scrollbar-thumb {
					background-color: #503291;
					-webkit-border-radius: 2em;
					-moz-border-radius: 2em;
					border-radius: 2em;
				}
				.popLetter_content {
					font-size: 18px;
					color: #333333;
					line-height: 23px;
				}
			}


			.popLetter_notes {
				position: absolute;
				top: 20px;
				left: 60px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				.icon {
					width: 42px;
					height: 50px;
					margin-bottom: 12px;
				}
				.text {
					font-size: 20px;
					color: #ffffff;
					line-height: 25px;
				}
			}
		}
	}
	//修复ui框架默认样式导致的样式错位
	.el-autocomplete-suggestion {
		width: 370px;
	}
	.el-dialog__body {
		max-height: 400px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.popLetter_fullscreenLoadingBox{
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background: transparent;
		z-index: 2200;
		.popLetter_fullscreenLoading{
			width: 200px;
			height: 200px;
			position: fixed;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			-webkit-transform: translate(-50%,-50%);
			.el-loading-mask{
				border-radius: 10px;
			}
			.el-loading-spinner i{
				color: #FFFFFF;
				font-size: 30px;
				margin-bottom: 20px;
			}
			.el-loading-spinner .el-loading-text{
				color: #FFFFFF;
				font-size: 18px;
			}
		}
	}
	.popLetter_cancelBtn {
		margin: 50px auto 0 !important;
		font-size: 18px;
		color: #999999;
		text-align: center;
	}
	.popLetter_sureBtn {
		width: 200px;
		height: 60px;
		line-height: 60px;
		margin: 20px auto;
		color: #ffffff;
		text-align: center;
		background: url('../icon/icon2.png') no-repeat center;
		background-size: 200px 60px;
		font-size: 18px;
	}
}
</style>
