<template>
	<div class="popLetter_black">
		<div class="popLetter">
			<div class="popLetter_box"><div class="popLetter_content" v-html="agreeMentObj.content"></div></div>
			<div class="perfectInformation_letter clearfix">
				<div @click.stop="agreeStatusChange">
					<div class="perfectInformation_agreeStatus"><div class="perfectInformation_agreeStatus_cont" v-if="agreeStatus"></div></div>
					<span class="notes">我已阅读</span>
				</div>
				<span class="letterBox">《个人信息收集同意书》</span><span class="notes_sub">以及其中所述对</span>
				<span class="notes padL20">我个人信息的跨境转移</span>
				
			</div>
			<div class="popLetter_sureBtn" @click.stop="noNeedLogin?null:agreeStatusSureBtn()">确认</div>
			<div class="popLetter_notes">
				<img alt="" class="icon" src="../icon/icon1.png" />
				<div class="text">个人信息收集同意书</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'informed-consent',
	props: {
		agreeMentObj: {
			type: Object
		},
		noNeedLogin: {
			type: Boolean,
			default: function() {
				return false;
			}
		},
		agreeStatus: {
			type: Boolean,
			default: function() {
				return false;
			}
		}
	},
	data() {
		return {};
	},
	mounted() {
		let that = this;
		console.log('知情同意书更新协议', that.agreeMentObj,that.noNeedLogin);
	},
	methods: {
		//是否同意知情同意书
		agreeStatusChange() {
			let that = this;
			that.$parent.agreeStatus = !that.$parent.agreeStatus;
		},
		agreeStatusSureBtn() { //通知父组件用户同意了协议更新
			let that = this;
			that.$parent.agreeStatusSureBtn();
		}
	}
};
</script>

<style scoped lang="scss">
.popLetter_black {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 100;
	.popLetter {
		width: 520px;
		min-height: 750px;
		margin-top: 20px;
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		background: url('../icon/bg-letter.png') no-repeat left top;
		background-size: 100% 750px;
		padding: 170px 60px 0 40px;
		.popLetter_box {
			width: 100%;
			margin: 0 auto;
			height: 380px;
			overflow-x: hidden;
			overflow-y: auto;
			&.fiveHeight {
				height: 480px;
			}
			&::-webkit-scrollbar {
				width: 15px;
			}
			&::-webkit-scrollbar-track {
				background-color: #d8d8d8;
				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}
			&::-webkit-scrollbar-thumb {
				background-color: #503291;
				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}
			.popLetter_content {
				font-size: 18px;
				color: #333333;
				line-height: 23px;
			}
		}
		.popLetter_sureBtn {
			width: 200px;
			height: 60px;
			line-height: 60px;
			margin: 20px auto 0;
			color: #ffffff;
			text-align: center;
			background: url('../icon/icon2.png') no-repeat center;
			background-size: 200px 60px;
			font-size: 18px;
		}
		.popLetter_notes {
			position: absolute;
			top: 20px;
			left: 60px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.icon {
				width: 42px;
				height: 50px;
				margin-bottom: 12px;
			}
			.text {
				font-size: 20px;
				color: #ffffff;
				line-height: 25px;
			}
		}
	}
	.perfectInformation_letter {
		width: 100%;
		margin: 20px 0 0;
		.perfectInformation_agreeStatus,
		.notes,
		.letterBox {
			float: left;
		}
		.perfectInformation_agreeStatus {
			width: 20px;
			height: 20px;
			border: 1px solid #503291;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			.perfectInformation_agreeStatus_cont {
				width: 10px;
				height: 10px;
				border: 1px solid #503291;
				border-radius: 50%;
				background: #eb3c96;
			}
			.el-checkbox__input.is-checked .el-checkbox__inner {
				background-color: #eb3c96;
				border-color: #eb3c96;
			}
			.el-checkbox__input.is-focus .el-checkbox__inner {
				border-color: #eb3c96;
			}
		}
		.notes,
		.notes_sub,
		.letterBox {
			font-size: 17.35px;
			color: #eb3c96;
			line-height: 22px;
			cursor: pointer;
		}
		.notes {
			color: #333333;
			margin-left: 8px;
		}
		.notes_sub{
			color: #333333;
		}
		.padL20{
			padding-left: 20px
		}
	}
}
</style>
