<template>
  <div class="product-container">
    <div class="scroll-container">
      <div class="bg-wrapper" v-if="!noNeedLogin">
        <div class="content-wrapper">
          <!-- 登录 -->
          <div class="item-login-wrap">
            <h1 class="item-title">欢迎登录</h1>
            <div class="item-login-content">
              <div class="login-box">
                <input v-model="form.phone" maxlength="11" class="phone" type="text"
                       placeholder="手机号"/>
                <div class="item-send-code">
                  <input v-model="form.code" maxlength="6" class="code" type="text"
                         placeholder="验证码"/>
                  <button :disabled="disabled" :class="['sendCode', { active: disabled }]"
                          @click="sendCode">{{codeBtnText}}
                  </button>
                </div>
              </div>
              <button class="item-login" :loading="loading" @click="loginApi">登录</button>
              <p class="item-tips">
                没有账号？
                <span class="item-regin" @click="goRegister">去微信公众号注册</span>
              </p>
             <div class="other-login">
               <div class="other-login-top clearfix">
                 <div class="lines1"></div>
                 <div class="other-login-desc">微信扫码登录</div>
                 <!-- <div class="other-login-desc">使用第三方账号登录</div> -->
                 <div class="lines2"></div>
               </div>
               <div class="other-login-bottom">
                 <!-- <a :href="`http://192.168.9.179:8080/otherLogin/auth?success=true&registerType=1&token=eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwNDQyYTc0NC1hNGY5LTQxNGQtODU4NS1iYThiOWEzNGViMTQiLCJDUkVBVEVEIjoxNjM4OTQ2OTQ1MDMzLCJleHAiOjE2Mzk1NTE3NDV9.b620cI5SLjWXiE-yDgXjJXWz-8O_5-3-xJ4MD0hKrvBeOLNEmlWZmShTcf4q4uqaf0OlzqoQzayOFdnozlOiHQ&errorMessage=需要申诉的`"> -->
                 <!-- <a :href="`http://192.168.9.179:8080/otherLogin/auth?success=true&registerType=1&token=eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmNTc4MmZhMy02OTA4LTQ0YzktYWEyMi01ZDY3NzY4MDQwMTUiLCJDUkVBVEVEIjoxNjM4OTQ1MDAyNzQxLCJleHAiOjE2Mzk1NDk4MDJ9.w5Q53dXdvW0s2b_U7zm552u9g6Fq4D4Zc1WAmb8rpBFyOowPnxQCnvph5NheyiFnUICQALn4AaBqIMxTssnHsQ&errorMessage=无效的用户`"> -->
                 <!-- <a :href="`http://192.168.9.179:8080/otherLogin/auth?success=true&registerType=1&token=eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJmNDRkYTA4MC03MTM2LTQwODMtYjNmZC02NzcyYzFiNjI0MzciLCJDUkVBVEVEIjoxNjM5NjI0NjYxNDk1LCJleHAiOjE2NDAyMjk0NjF9.IosMdUCm1reU6QqZdHOGE7roLB6fR4if5GXmVC0Cr1KNhqL7nQb2lALImpf_X_kfOX51VE-j7KIiCbgY2S9bkw&errorMessage=正常进入系统${$route.query.callBackUrl?('&callBackUrl='+$route.query.callBackUrl):''}`"> -->
                 <a :href="`${urlConfig.hostUrl}/pc/auth/getAuthCodeByWeChatQRCode${$route.query.callBackUrl?('?callBackUrl='+$route.query.callBackUrl):''}`">
                   <div class="wx-icon"></div>
                 </a>
               </div>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <perfect-modular v-if="loginSuccess_perfectInfoStatus" :userNewObj="userNewObj"
                     :perfectData="{ departmentArr, cityArr, agreeMentObj, isShowSubmitBtn, loginEdNeedApple,}"></perfect-modular>
    <informed-consent v-if="loginSuccess_agreeStatus" :agreeMentObj="agreeMentObj"
                      :agreeStatus="agreeStatus" :noNeedLogin="noNeedLogin"></informed-consent>
  </div>
</template>
<script>
import PerfectModular from '@/components/perfect/modular';

import InformedConsent from '@/components/informed-consent/informed-consent';

let timer = null;
let callBackUrl;
import indexApi from '@/api/index';
import urlConfig from "@/config/urlConfig";

export default {
  components: {
    PerfectModular,
    InformedConsent
  },
  data() {
    return {
      userNewObj: {}, //用户信息数据
      agreeMentObj: {}, //知情同意书最新数据
      loginSuccess_perfectInfoStatus: false, //用户登录成功，并且需要完善
      loginSuccess_agreeStatus: false, //用户登陆成功，并且协议有更新
      agreeStatus: false, //用户是否同意更新协议
      codeBtnText: '发送验证码',
      disabled: false,
      form: {
        phone: '', //13800000000
        code: '', //123456
      },
      loading: false,
      phoneEpx: /^[1][3456789][0-9]{9}$/,
      departmentArr: [], //科室数组
      cityArr: [], //科室数组
      noNeedLogin: false, //不需要登录框
      isShowSubmitBtn: true,
      loginEdNeedApple: false,
      urlConfig,
    };
  },
  mounted() {
    let that = this;
    callBackUrl = that.$route.query.callBackUrl;
    clearInterval(that.$options.timer);
    that.$options.timer = null;
    if(sessionStorage.getItem('needAppeal') == 'true'){
      sessionStorage.removeItem('needAppeal')
      that.retQuanshi();
    }
    let agreement_id = parseInt(that.$route.query.id);
    if (agreement_id && agreement_id >= 0) {
      console.log('获取最新的协议', agreement_id);
      that.noNeedLogin = true;
      that.getPrivacyAgreementId(agreement_id);
      return;
    }
    if (localStorage.getItem("token") && localStorage.getItem("fakeLogin")==true) {
      const {href} = this.$router.resolve({name: "Home"});
      location.replace(href);
    }
    let needAppeal = that.$route.params.needAppeal;
    if (needAppeal) {
      that.userNewObj = that.$route.params.data;
      that.getuserIdAppleal(that.userNewObj);
    } else {
      that.loginEdNeedApple = localStorage.getItem("loginEdNeedApple")
      switch (that.loginEdNeedApple) {
        case "true":
          that.userNewObj = JSON.parse(localStorage.getItem("userNewObj"));
         if (that.userNewObj.isAppeal === 1 && that.userNewObj.state === "invalid") {
            that.isShowSubmitBtn = true;
            that.loginEdNeedApple = false;
          } else {
           if (that.userNewObj.isViewCme === 0) {
             that.isShowSubmitBtn = false;
             that.loginEdNeedApple = true;
           } else {
             that.isShowSubmitBtn = true;
             that.loginEdNeedApple = false;
           }
          }
          that.loginSuccess_perfectInfoStatus = true;
          break;
        default:
          that.loginEdNeedApple = false;
          break;
      }
    }
    //获取最新知情同意书
    that.getPrivacyAgreement();
    //获取省市列表
    that.getCityList();
    //获取科室接口
    that.getDepartment();
  },
  methods: {
    retQuanshi(){
      let that = this;
      let params = {type: 0};
      indexApi.getUserInfo(params).then((res) => {
        that.getuserIdAppleal(res.data);
      })
    },
    //通过id去获取需要展示的知情同意书
    getPrivacyAgreementId(id) {
      let that = this;
      let params = {id};
      indexApi.getPrivacyAgreementId(params).then(res => {
        console.log(res, '通过id去获取需要展示的知情同意书');
        that.loginSuccess_agreeStatus = true;
        that.agreeMentObj = res.data;
      });
    },
    //用户申诉\更新协议
    getuserIdAppleal(data) {
      let that = this;
      that.userNewObj = data;
      console.log(data, "用户信息");
       that.$store.commit('setUserType', '');
      if (data.type == 0 || data.type == 3) {
        if (data.type == 3) {
          
          if (data.state == 'valid') {
            if(data.isViewCme == 0){
             
              that.$message.error('该板块暂未开放，暂时无法访问网站');
              return
            }
          }else{
            that.$message.error(data.message);
            if(data.isSecondRegister == 1){
              this.goRegister()
            }
            return
          }
          if(data.isScan == 1){

          }
        }
        switch (data.state) {
          case "valid":
            let token = data && data.token;
            that.$stroage.setLocalItem('token', token);
            that.$store.commit('setUserName', data.userInfo.name);
            that.$store.commit('setUserAvatar', data.userInfo.avatar);
            that.$message.success(data.message);
            if (data && data.isPrivacy === 1) { //0-不弹隐私协议 1-弹出隐私协议
              that.loginSuccess_agreeStatus = true;
              return;
            } else {
              that.$message.success(data.message);
              let token = data && data.token;
              that.$stroage.setLocalItem('token', token);
              that.$store.commit('setUserName', data.userInfo.name);
              that.$store.commit('setUserAvatar', data.userInfo.avatar);
              if (data && data.tagStatus === 1) { //1前往标签页面
                that.$router.push({name: 'registerNext'});
              } else if (data && data.tagStatus === 0) { //0跳过标签页面，直接进入首页
                if (data.type == 3) {
                  that.$store.commit('setUserType', data.type);
                  that.$router.push({
                  path: "/conference-hall",
                 })
                }else{
                   that.$router.push({path: '/home'});
                }
                 
              }
              localStorage.setItem('fakeLogin', false);
            }
            break;
          case "invalid":
          case null:
          case "":
            console.log("登录无效账号,信息:", data);
            // that.$message.error("无效账号，请联系管理员");
            let userCenterId = data.userCenterId;
            let params = {doctorId: userCenterId};
            that.$point.getUserCenterAppeal(params, (res) => {
              console.log(res, "获取申诉信息接口")
              if (res.code === 0 && !res.success) {
                that.$message.error(res.message);
                return;
              }
              that.$message.error(res.data.message);
              data["appealUser"] = res.data;
              if (res.data.isAppeal === 1 && res.data.state === "invalid"){
                that.isShowSubmitBtn = true;
                that.loginEdNeedApple = false;
              } else {
                that.isShowSubmitBtn = false;
                that.loginEdNeedApple = true;
              }
              that.$stroage.setLocalItem('loginEdNeedApple', true);
              that.$stroage.setLocalItem('userNewObj', data);
              that.loginSuccess_perfectInfoStatus = true;
            })
            break;
          case "temporary":
          case "checked":
            let _token = data && data.token;
            that.$stroage.setLocalItem('token', _token);
            that.$store.commit('setUserName', data.userInfo.name);
            that.$store.commit('setUserAvatar', data.userInfo.avatar);
            if (data.isAppeal === 1) {
              let userCenterId = data.userCenterId;
              let params = {doctorId: userCenterId};
              that.$point.getUserCenterAppeal(params, (res) => {
                console.log(res, "获取申诉信息接口")
                if (res.code === 0 && !res.success) {
                  that.$message.error(res.message);
                  return;
                }
                that.$message.error(res.data.message);
                data["appealUser"] = res.data;
                that.isShowSubmitBtn = true;
                that.loginEdNeedApple = false;
                that.$stroage.setLocalItem('loginEdNeedApple', true);
                that.$stroage.setLocalItem('userNewObj', data);
                that.loginSuccess_perfectInfoStatus = true;
              })
              return;
            } else if (data.isViewCme === 0) {
              let userCenterId = data.userCenterId;
              let params = {doctorId: userCenterId};
              that.$point.getUserCenterAppeal(params, (res) => {
                console.log(res, "获取申诉信息接口")
                if (res.code === 0 && !res.success) {
                  that.$message.error(res.message);
                  return;
                }
                that.$message.error(res.data.message);
                data["appealUser"] = res.data;
                that.isShowSubmitBtn = false;
                that.loginEdNeedApple = true;
                that.$stroage.setLocalItem('loginEdNeedApple', true);
                that.$stroage.setLocalItem('userNewObj', data);
                that.$stroage.setLocalItem('token', "");
                that.loginSuccess_perfectInfoStatus = true;
              })
              return;
            } else if (data && data.isPrivacy === 1) { //0-不弹隐私协议 1-弹出隐私协议
              that.loginSuccess_agreeStatus = true;
              return;
            } else {
              that.$message.success(data.message);
              let token = data && data.token;
              that.$stroage.setLocalItem('token', token);
              that.$store.commit('setUserName', data.userInfo.name);
              that.$store.commit('setUserAvatar', data.userInfo.avatar);
              if (data && data.tagStatus === 1) { //选择标签
                that.$router.push({name: 'registerNext'});
              } else if (data && data.tagStatus === 0) { //进入首页
                that.$router.push({path: '/home'});
              }
              localStorage.setItem('fakeLogin', false);
            }
            break;
        }
      } else {
        switch (data.state) {
          case "valid":
            if (data.isViewCme === 0) {
              that.$message.error(data.message);
              return;
            }
            let token = data && data.token;
            that.$stroage.setLocalItem('token', token);
            that.$store.commit('setUserName', data.userInfo.name);
            that.$store.commit('setUserAvatar', data.userInfo.avatar);
            that.$message.success(data.message);
            if (data && data.isPrivacy === 1) { //0-不弹隐私协议 1-弹出隐私协议
              that.loginSuccess_agreeStatus = true;
              return;
            } else {
              that.$message.success(data.message);
              let token = data && data.token;
              that.$stroage.setLocalItem('token', token);
              that.$store.commit('setUserName', data.userInfo.name);
              that.$store.commit('setUserAvatar', data.userInfo.avatar);
              if (data && data.tagStatus === 1) { //1前往标签页面
                that.$router.push({name: 'registerNext'});
              } else if (data && data.tagStatus === 0) { //0跳过标签页面，直接进入首页
                that.$router.push({path: '/home'});
              }
              localStorage.setItem('fakeLogin', false);
            }
            break;
          case "invalid":
          case null:
          case "":
            console.log("登录无效账号,信息:", data);
            that.$message.error("无效账号，请联系管理员");
            break;
        }
      }
    },
    //获取科室接口
    getDepartment() {
      let that = this;
      let params = {};
      indexApi.getDepartment(params).then(
        res => {
          console.log(res, '科室数据');
          if (res.success && res.code === 1000) {
            res.data.forEach((item, index) => {
              let merck_department_list = [];
              let departmentItems;
              if (item.merck_department_name && item.merck_department_name.length >= 1) {
                item.merck_department_name.forEach(childItem => {
                  merck_department_list.push({
                    label: childItem.merck_department_name,
                    value: childItem.merck_department_name
                  });
                  departmentItems = {
                    label: item.merck_first_department_name,
                    value: item.merck_first_department_name,
                    children: merck_department_list
                  };
                });
              }
              that.departmentArr.push(departmentItems);
            });
          } else {
            that.departmentArr = [];
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    //获取省市数据
    getCityList() {
      let that = this;
      let params = {};
      indexApi.getCityList(params).then(
        res => {
          console.log(res, '省市数据');
          if (res.success && res.code === 1000) {
            that.cityArr = res.data;
          } else {
            that.cityArr = [];
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    //获取知情同意书
    getPrivacyAgreement() {
      let that = this;
      let params = {};
      indexApi.getPrivacyAgreement(params).then(
        res => {
          if (res.success && res.code === 1000) {
            that.agreeMentObj = res.data;
          } else {
            that.agreeMentObj = {content: ""};
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    //提交用户同意协议更新操作
    agreeStatusSureBtn() {
      let that = this;
      if (!that.agreeStatus) {
        that.$message.error('请先阅读《个人信息收集同意书》');
        return;
      }
      let params = {
        version: that.agreeMentObj.version,
        platform: 'pc',
        isArgee: that.agreeStatus ? 1 : 0,
      }
      indexApi.getAgreementChoose(params).then((res) => {
          console.log(res);
          if (res.code === 1000 && res.success) {
            that.loginSuccess_agreeStatus = !that.loginSuccess_agreeStatus;
            that.agreeStatus = false;
            let token = that.userNewObj && that.userNewObj.token;
            that.$stroage.setLocalItem('token', token);
            that.$store.commit('setUserName', that.userNewObj.userInfo.name);
            that.$store.commit('setUserAvatar', that.userNewObj.userInfo.avatar);
            if (that.userNewObj && that.userNewObj.tagStatus === 1) { //1进入选择标签页
              that.$router.push({name: 'registerNext'});
            } else if (that.userNewObj && that.userNewObj.tagStatus === 0) { //0跳过选择标签页，直接进入首页
              that.$router.push({name: 'home'});
            }
            localStorage.setItem('fakeLogin', false);
          } else {
            console.log("提交失败，请稍后重试:" + res.message);
          }
        },
        err => {
          console.log('提交失败', err.message);
          this.$message.error((err && err.message) || '提交失败，请联系管理员！');
        })

    },
    // 去登陆
    goLogin() {
      this.type = 'login';
    },
    // 去注册
    goRegister() {
      // this.$router.push({ name: 'register',query:{callBackUrl}});
      const {href} = this.$router.resolve({name: "register"});
      let registerPath = callBackUrl?`${href}?callBackUrl=${callBackUrl}`:href;
      console.log("registerPath", registerPath)
      location.replace(registerPath);
    },
    // 发送验证码事件
    sendCode() {
      let isPhone = this.phoneEpx.test(this.form.phone);
      if (!this.form.phone) {
        this.$message.error('请输入手机号');
        return;
      } else if (!isPhone) {
        this.$message.error('手机号输入有误');
        return;
      }
      this.sendCodeHandle();
    },
    // 发送验证码逻辑
    sendCodeHandle() {
      this.sendSmsCode();
    },
    // 发送验证码
    sendSmsCode() {
      let params = {
        phone: this.form.phone,
        type: 'login'
      };
      indexApi.sendSmsCode(params).then(
        res => {
          if (res && res.code === 1000) {
            this.$message.success('发送成功');
            let secondCount = 60;
            timer = setInterval(() => {
              if (secondCount > 0) {
                this.disabled = true;
                this.codeBtnText = secondCount + 's后再发送';
                secondCount--;
              } else {
                clearInterval(timer);
                this.codeBtnText = '发送验证码';
                secondCount = 60;
                this.disabled = false;
              }
            }, 1000);
          } else {
            console.log('失败=========', res.message);
            this.$message.error((res && res.message) || '发送失败，请联系管理员！');
          }
        },
        err => {
          this.$message.error((err && err.message) || '发送失败，请联系管理员！');
        }
      );
    },
    // 登录
    loginApi() {
      let that = this;
      let isPhone = that.phoneEpx.test(that.form.phone);
      if (!that.form.phone || !isPhone) {
        that.$message.error('请输入正确的手机号');
        return;
      } else if (!that.form.code) {
        that.$message.error('请输入验证码');
        return;
      }
      let params = {
        phone: that.form.phone,
        code: that.form.code
      };
      that.loading = true;
      const loading = this.$loading({
        lock: true,
        text: '正在登录，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,.5)',
      });
      indexApi.login(params).then(
        res => {
          console.log(res, "登录接口");
          loading.close();
          if (res && res.code === 0) { //登录失败
            that.$message.error(res.message);
            return;
          } else if (res && res.code === 1000) {

            let userCenterId = res.data.userCenterId;
            let params = {doctorId: userCenterId};
            // isAppeal
            // 临时身份  isAppeal为1或者isViewCme为0都要进入申诉页，
            // isAppeal  0   isViewCme 0   进申诉不能提交
            // isAppeal  1   isViewCme 0   进申诉可以提交，提交完不可以进入系统
            // isAppeal  0   isViewCme 1   进系统
            // isAppeal  1   isViewCme 1   进申诉可以提交，提交完进入系统
            if (callBackUrl) {
              let token = res.data && res.data.token;
              that.$stroage.setLocalItem('token', token);
              that.$store.commit('setUserName', res.data.userInfo.name);
              that.$store.commit('setUserAvatar', res.data.userInfo.avatar);
              const {href} = this.$router.resolve({name: "Quanshi"});
              let loginPath = `${href}?callbackUrl=${callBackUrl}`;
              console.log("loginPath", loginPath)
              localStorage.setItem('fakeLogin', true);
              location.replace(loginPath);
            } else {
              // that.$message.success(res.data.message);
              that.getuserIdAppleal(res.data);
            }
          }
        },
        err => {
          console.log('失败=========2', err.message);
          this.$message.error((err && err.message) || '登录失败，请联系管理员！');
          this.loading = false;
        }
      );
    },
    throttle(fun) { // 节流函数
      let flag = true;
      return function () {
        if (!flag) return;
        flag = false;
        setTimeout(() => {
          fun.apply(this, arguments);
          flag = true;
        }, 1000);
      };
    },
    // 个人中心数据
    getUserInfo() {
      let that = this;
      let params = {type: 0};
      indexApi.getUserInfo(params).then((res) => {
        console.log("调用了获取用户信息接口：", res.data);
        if (res && res.code === 1000) {
          if (res.data && res.data.state === "valid" || res.data && res.data.state === "temporary" || res.data && res.data.state === "checked") {
            if (res.data && res.data.state === "temporary" || res.data && res.data.state === "checked") {
              if (res.data.isAppeal === 1) {
                that.userNewObj = res.data;
                let userCenterId = res.data.userCenterId;
                let _params = {doctorId: userCenterId};
                that.$point.getUserCenterAppeal(_params, (res) => {
                  console.log(res, "获取申诉信息接口")
                  if (res.code === 0 && !res.success) {
                    that.$message.error(res.message);
                    return;
                  }
                  that.$message.error(res.data.message);
                  that.userNewObj["appealUser"] = res.data;
                  if (data && data.state === "invalid" || that.userNewObj.isAppeal === 0 && that.userNewObj.isViewCme === 0) {
                    that.isShowSubmitBtn = false;
                    that.loginEdNeedApple = true;
                    that.$stroage.setLocalItem('loginEdNeedApple', true);
                    that.$stroage.setLocalItem('userNewObj', that.userNewObj);
                    that.$stroage.setLocalItem('token', "");
                  }
                  that.loginSuccess_perfectInfoStatus = true;
                })
                return;
              } else if (res.data.isPrivacy === 1) {
                that.loginSuccess_agreeStatus = true;
                return;
              }
            }
            let data = res && res.data;
            that.userInfo = data;
            that.currUserName = data.name;
            that.currAvatar = data.avatar;
            that.$store.commit('setUserName', data.name);
            that.$store.commit('setUserAvatar', data.avatar);
          }
        } else {
          that.$message.error(res.message);
        }
      }, (err) => {
      })
    },
  }
};
</script>

<style scoped lang="scss">
.product-container {
  background-image: url('~./images/bg.jpg');
  background-size: cover;
  line-height: 1;
  position: relative;

  .bg-wrapper {
    display: flex;
    align-items: flex-start;
    background: url('~./images/bg-login.png') center center / 100% 100%;
    width: 10.6rem;
    height: 7.4rem;
    margin: 0.6rem auto 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // padding-left: .3rem;
    // padding-top: .4rem;
    // padding-bottom: .4rem;
  }

  .content-wrapper {
    width: 100%;

    .item-login-wrap,
    .item-register-wrap {
      width: 100%;

      .item-title {
        text-align: center;
        font-size: 0.4rem;
        font-family: Noto Sans CJK SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 0.48rem;
        text-align: center;
        margin-left: -0.06rem;
        margin-top: 0.81rem;
      }

      .item-login-content {
        width: 4.2rem;
        margin: 1.59rem auto 0;

        .phone {
          width: 4rem;
          height: 0.52rem;
          background: #f5f5f7;
          padding: 0.05rem 0.2rem;
          border: 1px solid #dddddd;
          border-radius: 0.06rem;
          font-size: 0.16rem;
          font-family: Noto Sans CJK SC;
          font-weight: 400;
          color: #999999;
          display: block;
          outline: none;
        }

        .item-send-code {
          margin-top: 0.25rem;

          .code {
            width: 2.5rem;
            height: 0.52rem;
            background: #f5f5f7;
            padding: 0.05rem 0.2rem;
            border: 1px solid #dddddd;
            border-radius: 0.06rem;
            font-size: 0.16rem;
            font-family: Noto Sans CJK SC;
            font-weight: 400;
            color: #999999;
            outline: none;
            margin-right: 0.1rem;
            float: left;
          }

          .sendCode {
            width: 1.4rem;
            height: 0.52rem;
            background: #eb3c96;
            border-radius: 0.06rem;
            font-size: 0.16rem;
            font-family: Noto Sans CJK SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.48rem;
            cursor: pointer;

            &.active {
              background: #999999;
            }
          }
        }

        .item-login {
          width: 4rem;
          height: 0.52rem;
          background: #503291;
          border-radius: 0.06rem;
          font-size: 0.18rem;
          font-family: Noto Sans CJK SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 0.48rem;
          text-align: center;
          margin-top: 0.25rem;
          cursor: pointer;
        }
        .item-tips {
          text-align: right;
          font-size: 14px;
          font-family: Noto Sans CJK SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          margin: 0.21rem 0.3rem 0 0;

          span {
            cursor: pointer;
            color: #2ba246;
            // color: #EB3C96;
            &::after {
              content: '';
              width: 0.16rem;
              height: 0.16rem;
              display: inline-block;
              vertical-align: middle;
              background: url('./images/0.png') no-repeat center / 100% 100%;
              margin-left: 0.1rem;
              margin-top: -0.02rem;
            }
          }
        }

        .other-login{
          margin-top: 28px;
          .other-login-top{
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .lines1,.lines2{
              width: 1rem;
              height: 1px;
              background: #E3E7EE;
            }
            .other-login-desc{
              flex: 1;
              text-align: center;
              font-size: 14px;
              color: #333333;
            }
          }
          .other-login-bottom{
            text-align: center;
            margin-top: 27px;
            .wx-icon{
              width: 46px;
              height: 46px;
              background: url("./images/wx-icon.png") center no-repeat;
              background-size: 100% 100%;
              display: inline-block;
              cursor: pointer;
            }
          }
        }
      }
    }

    .item-register-wrap {
      .item-send-code,
      .item-login {
        margin-top: 0 !important;
      }

      .item-login-content {
        width: auto !important;
        margin: 1.6rem 1rem 0;
      }

      .item-left-wrap {
        width: 4rem;
        float: left;
      }

      .item-right-wrap {
        width: 4rem;
        float: right;
      }

      .el-input {
        height: 0.52rem;
      }

      .el-input__inner {
        font-size: 0.16rem !important;
        background: #f5f5f7;
        font-family: Noto Sans CJK SC;
        font-weight: 400;
        color: #999999;
        height: 0.52rem;
      }
    }
  }
}
@media screen and (max-width: 1550px) {
    .product-container .content-wrapper .item-login-wrap .item-login-content {
        margin: 1.15rem auto 0;
    }
}
@media screen and (max-width: 1350px) {
    .product-container .content-wrapper .item-login-wrap .item-login-content {
        margin: 1rem auto 0;
    }
}
@media screen and (max-width: 1250px) {
    .product-container .content-wrapper .item-login-wrap .item-login-content {
        margin: 0.8rem auto 0;
    }
}
</style>
